a:visited {
  color: inherit;
}

.info-item {
  padding: 12px 28px;
}

.info-title {
  padding-top: 8px;
  padding-left: 8px;
}
