.share-button {
  background-color: #2E8B57;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  height: 36px;
  vertical-align: bottom;
  width: 72px;
}

.share-container {
  padding-top: 12px;
}
