.autocomplete {
  display: inline-block;
  margin-top: 8px;
  max-width: 100%;
  position: relative;
}

.autocomplete-items {
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-radius: 4px;
  left: 0;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 99;
  }

.autocomplete-items div {
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 10px;
}

.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.button {
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  height: 36px;
  vertical-align: bottom;
  width: 72px;
}

.hidden {
    display: none;
}

.input {
  border: none;
  border-radius: 4px;
  caret-color: black;
  color: #373737;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  height: 32px;
  max-width: 100%;
  width: 450px;
}

input:focus {
  outline: none;
}

.quote {
  font-family: 'Courier New', Courier, monospace;
  margin: 16px 0;
  max-width: fit-content;
}

.quotes-container {
    background: lightgray;
    margin: 24px auto;
    padding: 24px 24px 40px 24px;
    position: relative;
    min-height: 400px;
    max-width: 500px;
    text-align: left;
}

.quotes-container:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 24px 24px 0;
  border-style: solid;
  border-color: darkgray #31353F;
}

.quotes-header {
  font-family: 'Courier New', Courier, monospace;
  font-size: 20px;
  font-weight: bold;
}

.skip {
  background-color: gray;
  margin-top: 12px;
}

.submit {
  background-color: #2E8B57;
}
