.modal-body {
  color: #373737;
  display: grid;
  justify-content: center;
}

.row {
  display: inline-flex;
  margin: auto;
  padding: 40px 32px 0 32px;
}

.stat-item {
  display: grid;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-weight: bold;
  padding: 0 16px;
}

.stat-title {
  color: #565656;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 20px;
}

.top-row-item {
  padding: 0 56px;
}
