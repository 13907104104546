.modal {
  background-color: lightgray;
  border-radius: 4px;
  box-shadow: 0px 0px 2px 2px #53565A;
  height: 400px;
  left: 0;
  margin: auto;
  max-width: 90%;
  overflow: overlay;
  position: absolute;
  right: 0;
  width: 400px;
  z-index: 2;
}

.modal-header {
  align-items: center;
  display: flex;
}

.modal-title {
  color: #373737;
  flex: 9;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 24px;
  padding: 8px 0 0 8px;
  text-align: left;
}
