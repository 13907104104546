.active {
  background-color: #646464 !important;
  border: 1px solid black !important;
}

.image {
  height: 354px;
  width: 630px;
}

@media screen and (max-width: 500px) {
  .image {
      height: auto;
      max-width: 100%;
      min-height: 210px;
      width: 630px;
  }
}

.loading {
  align-items: center;
  color: white;
  display: flex;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 24px;
  height: 354px;
  justify-content: center;
  margin: auto;
  width: 630px;
}

.screenshot {
  padding: 8px 0;
}

.screenshotNav {
  padding-bottom: 12px;
}

.screenshotNav>button {
  background-color: darkgray;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  height: 44px;
  margin: 0 4px;
  width: 36px;
}
