.autocomplete {
  display: inline-block;
  margin-top: 8px;
  max-width: 100%;
  position: relative;
}

.autocomplete-items {
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-radius: 4px;
  left: 0;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 99;
  }

.autocomplete-items div {
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 10px;
}

.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.input {
  border: none;
  border-radius: 4px;
  caret-color: black;
  color: #373737;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  height: 32px;
  max-width: 100%;
  width: 450px;
}

input:focus {
  outline: none;
}

.input-container {
  justify-content: center;
}

.button {
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  height: 36px;
  vertical-align: bottom;
  width: 72px;
}

.guesses-remaining {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-top: 16px;
}

.skip {
  background-color: gray;
  margin-top:8px;
}

.skip:active {
  -webkit-box-shadow: inset 0px 0px 5px #404040;
  -moz-box-shadow: inset 0px 0px 5px #404040;
  box-shadow: inset 0px 0px 5px #404040;
}

.submit {
  background-color: #2E8B57;
}

.submit:active {
  -webkit-box-shadow: inset 0px 0px 5px #17452b;
  -moz-box-shadow: inset 0px 0px 5px #17452b;
  box-shadow: inset 0px 0px 5px #17452b;
}

.submitWrapper {
  padding: 0 12px;
  margin-top: 8px;
}

.title {
  color: white;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 32px;
}
