.back-button {
    background-color: blueviolet;
    margin-top: 32px;
}

.button-global {
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    height: 36px;
    width: 72px;
}

.col-header {
    background-color: blueviolet;
    height: 32px;
}

.end-button {
    background-color: gray;
    margin-left: 8px;
    margin-top: 32px;
}

.game-container {
    max-width: 100%;
}

.guess-emoji {
    cursor: pointer;
    margin: 4px;
}

.guess-emoji-container {
    margin-top: 12px;
}

.high-score-title {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin: 0;
}

.instruction {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    padding-top: 12px;
}

.instructions-container {
    background: lightgray;
    border-radius: 4px;
    margin: 24px auto;
    padding: 24px 24px 40px 24px;
    max-width: 500px;
}

.join-button {
    background-color: blueviolet;
    margin-top: 24px;
}

.leaderboard-back-button {
    background-color: blueviolet;
    margin-top: 12px;
}

.leaderboard-button {
    width: fit-content;
}

.leaderboard-container {
    max-height: 100%;
}

.leaderboard-input {
    border: none;
    border-radius: 4px;
    caret-color: black;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    height: 32px;
    margin-top: 24px;
    max-width: 100%;
    padding: 0 8px;
}

.leaderboard-instruction {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: bold;
    padding-top: 32px;
}

.leaderboard-table {
    border: 1px solid black;
    border-spacing: 0px;
    margin-top: 24px;
    width: 100%;
}

.leaderboard-text {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-top: 8px;
}

.placement {
    margin-top: 16px;
}

.play-again-button {
    background-color: blueviolet;
    margin-top: 32px;
    width: 104px;
}

.score {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    padding-top: 20px;
}

.start-button {
    background-color: blueviolet;
    margin-top: 5%;
}

.stats-button {
    background-color: blueviolet;
    margin-left: 8px;
    margin-top: 5%;
}

.subtitle {
    margin-bottom: 0;
    margin-top: 24px;
}

td, th {
    border: 1px solid black;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0.5em;
}

td {
    background-color: #ededed;
}

.text-block {
    margin-top: 12px;
}

.read-more-button {
    background-color: blueviolet;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    height: 32px;
    margin-top: 12px;
    width: 92px;
  }

  .update-text {
    background: lightgrey;
    border-radius: 4px;
    color: black;
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin: 12px 0;
    width: 22em;
    padding: 16px;
  }
