.correct-guess {
  background-color: #2E8B57;
}

.episode-number {
  color: darkgray;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.incorrect-guess {
  background-color: #E50000;
}

.null-guess {
  background-color: #646464;
}

.result-container {
  padding-top: 16px;
}

.result-icon {
  border-radius: 4px;
  height: 24px;
  margin: 4px;
  width: 24px;
}

.result-icons {
  display: flex;
  margin: auto;
  padding-top: 24px;
  width: fit-content;
}

.result-text {
  color: darkgray;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  padding-bottom: 8px;
}

.title {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
