.active-mode {
  color: white !important;
  font-weight: bold;
}

.mode-option {
  color: darkgray;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px !important;
  margin: 0 8px;
  cursor: pointer;
}

.modes-container {
  align-items: center;
  padding-top: 8px;
}
