.instructions-body {
  color: #575757;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-top: 24px;
}

.instruction-item {
  padding: 8px 28px;
}

.instructions-title {
  padding-top: 8px;
  padding-left: 8px;
}
